import React, { useState } from 'react'
import { TextField } from '@mui/material'

import Button from '../components/Button/Button'
import axios from 'axios';
import { BASE_URL } from '../contants/constants';
import { toast } from 'react-hot-toast';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);

   
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setValidEmail(validateEmail(event.target.value));
        
      };
       
      const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };
      const handleSubmitLogin = (event)=>{
 
        event.preventDefault()
            axios.get(BASE_URL+'api_reset.php?user_email='+email).then((response)=>{
              toast.success(response.data.message)
            })
          
        // dispatch(login(email , password))
      }
  return (

    <div className="sign-page-div">
                <div  className="sign-up-form ">
                <h2 style={{ alignSelf:'center'}} className='headings'>Forgot Password</h2>
                <label style={{ alignSelf:'center', textAlign:'center', fontWeight:'200 !important', fontSize:'15px'}}  htmlFor="">Please enter your email address to receive a reset password link</label>

                <div className="signin-field-container">
              <form style={{display  : 'flex' , flexDirection: 'column' , gap : '20px'}} onSubmit={handleSubmitLogin}>
              <TextField 
                
                error={!validEmail}
                helperText={!validEmail ? 'Invalid email address' : ''}
                onChange={handleEmailChange}
                onFocus={handleEmailChange}
                required type='email' style={{width:'100%'}}  label="Email" variant="outlined" />
                <div onClick={handleSubmitLogin}>
                 <Button type={'submit'} disabled={!validEmail }  text={'Recover Password'} height={'50px'} width={'100%'} color={validEmail? '#FF5000' : '#E7E7E7'} textColor={'#fff'} border={'none'}/></div>
                 </form>
            </div>
            </div>
    </div>
  )
}

export default ForgotPassword
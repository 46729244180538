// export const BASE_URL = 'https://api.emay.me/webapi/';
export const BASE_URL = 'https://froffer.com/webapinew/';
export const BASE_URL_WEB = 'https://froffer.com/';

export const IMG_VOUCHER = 'https://froffer.com/assets/img/voucher/'
export const IMG_COUPON = 'https://froffer.com/assets/img/coupon/'
export const IMG_CATEGORY =  'https://froffer.com/assets/img/category/'
export const IMG_BRANCH = 'https://froffer.com/assets/img/branch/'
export const IMG_MERCHANT = 'https://froffer.com/assets/img/merchant/'
export const IMG_SLIDER = 'https://froffer.com/assets/img/slider/'


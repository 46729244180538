import React from 'react'

function Button({ type,disabled, setData,text, textColor , color, height , width , border , cl ,onClick}) {
    const buttonStyle = {
        backgroundColor: color,
        color: textColor ,
        height : height,
        width : width,
        borderRadius: '8px',
        cursor: 'pointer',
        border: '1px solid rgba(23, 23, 23, 0.82)',
        // eslint-disable-next-line
        border : border,
        borderColor: '#171717',
        fontSize : "15px"
      };
  return (
      <button type={type} disabled = {disabled} onClick={onClick} style={buttonStyle} className={!cl ? 'sign' : 'giftbtn'}>{text}</button>

  )
}

export default Button
import './App.css';
import React ,{lazy,Suspense} from 'react';
import { useLocation } from 'react-router-dom';
import toast ,{ Toaster } from 'react-hot-toast';
import { ProtectedRoute } from 'protected-route-react';
import { useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router,Routes ,Route } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import ForgotPassword from './pages/ForgotPassword';
import {BsWhatsapp} from 'react-icons/bs'
const Home = lazy(() => import('./pages/Home'));
const NavBar = lazy(() => import('./components/NavBar/NavBar'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Product = lazy(() => import('./pages/Product'));
const CouponDetails = lazy(() => import('./pages/CouponDetails'));
const UserDetail = lazy(() => import('./pages/UserDetails'));
const Sign = lazy(() => import('./pages/Sign'));
const AddToCartPage = lazy(() => import('./pages/AddToCartPage'));
const ContactForm = lazy(() => import('./components/ContactForm/ContactForm'));
const FAQ = lazy(() => import('./pages/FAQ'));
const About = lazy(() => import('./pages/About'));
const Reset = lazy(() => import('./pages/Reset'));
const MessagePage = lazy(()=>import('./pages/MessagePage'))

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  
  const {isAuthenticated, user , message ,error} =  useSelector(state =>state.user)
  const dispatch = useDispatch()
  useEffect(()=>{
    if(error){
      toast.error(error)
      dispatch({type : 'clearError'})
    }
    if(message){
      toast.success(message)
    
      dispatch({type : 'clearMessage'})
    }

  },[dispatch, error , message])
  
  return (
    
    <div className='max-width-container'>
    <Router>
    <Suspense fallback={<Loader/>}>

    <ScrollToTop />
    <NavBar isAuthenticated={isAuthenticated} user={user} />
      <Routes>
      <Route path="/" element={<Home  />} />
      <Route path="/product" element={<Product />} />
      <Route path="/coupon" element={<CouponDetails/>} />      
      <Route path="/faq" element={<FAQ/>} />     
      <Route path="/about" element={<About/>} />     
      <Route path="/contact" element={<ContactForm/>} />     
      <Route path="/privacy" element={<Privacy/>} />
      <Route path="/reset" element={<Reset/>} />
      <Route path="/verify" element={<MessagePage/>} />
      <Route path="/forget" element={<ForgotPassword/>} />
      <Route path="/sign-in" element={ <ProtectedRoute isAuthenticated= {!isAuthenticated} redirect={'/'}>
                  <Sign formDetails={false} />
                </ProtectedRoute>} />
                <Route path="/sign-up" element={ <ProtectedRoute isAuthenticated = {!isAuthenticated} redirect={'/'}>
                  <Sign formDetails={true}/>
                </ProtectedRoute>} />
    
          <Route path="/payment"  element={ <ProtectedRoute isAuthenticated= {isAuthenticated} redirect={'/'}>
                  <AddToCartPage/>
                </ProtectedRoute>} />
          <Route path="/account"  element={ <ProtectedRoute isAuthenticated= {isAuthenticated} redirect={'/'}>
                  <UserDetail/>
                </ProtectedRoute>} />
     </Routes>
    <Footer />
    <div
            onClick={()=>{window.open('https://wa.me/+971566802619', '_blank')}}
            className='whatsapp-floating-button'
           
          >
            {/* <img src='path/to/whatsapp-icon.png' alt='WhatsApp' /> */}
            <BsWhatsapp size={'30px'} fill='#25d366'/>
            <label className='whatsapp-label' style={{cursor:'pointer'}}>Chat with us <br/> <span style={{color:'#25d366'}}>WhatsApp</span></label> 
          </div>
    <Toaster/>
    </Suspense>
  </Router>
  </div>
 
  );
}

export default App;

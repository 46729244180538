import { createReducer } from "@reduxjs/toolkit";

export const voucherReducer = createReducer({},{
    getVouchersRequest : (state )=>{
        state.loading =true
    },
    getVouchersSuccess : (state , action)=>{
        state.loading = false;
        state.vouchers = Array.isArray(action.payload.message) ? action.payload.message : [] ;
        state.like  = action.payload.likecount
        state.response = action.payload.response;
    },
    getVouchersFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
  
    clearError : (state)=>{
        state.error = null 
    },
    clearMessage : (state)=>{
        state.message = null
    }
}) 
export const merchantsReducer = createReducer({},{
    getMerchantsRequest : (state )=>{
        state.loading =true
    },
    getMerchantsSuccess : (state , action)=>{
        state.loading = false;
        state.merchants = action.payload.message;
    },
    getMerchantsFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    clearError : (state)=>{
        state.error = null 
    },
    clearMessage : (state)=>{
            state.message = null
    }
}) 
export const categoriesReducer = createReducer({},{
    getCategoriesRequest : (state )=>{
        state.loading =true
    },
    getCategoriesSuccess : (state , action)=>{
        state.loading = false;
        state.categories = action.payload.message;
    },
    getCategoriesFail : (state , action)=>{
        state.loading = false
        state.error = action.payload.message 
    },
    clearError : (state)=>{
        state.error = null 
    },
    clearMessage : (state)=>{
            state.message = null
    }
}) 
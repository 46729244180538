import React from 'react';
import ReactDOM from 'react-dom/client';
import ErrorBoundary from './components/ErrorBoundry/ErrorBoundry';
import App from './App';
import { Provider } from 'react-redux';
import store from './Redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    
     <GoogleOAuthProvider  clientId="1001704439288-i4ir4ddb2bo9o9b0cri1ptomenhrh5hu.apps.googleusercontent.com">
    <React.StrictMode>
        <ErrorBoundary fallback={<p>Something went wrong, Please refresh</p>}>
          <App />
        </ErrorBoundary>
      </React.StrictMode>
</GoogleOAuthProvider>,
  </Provider>
);


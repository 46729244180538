import { configureStore } from "@reduxjs/toolkit";
import { BASE_URL} from '../contants/constants'
import { userReducer } from "./reducers/userReducer";
import { categoriesReducer, merchantsReducer,voucherReducer} from './reducers/voucherReducer'
export const server = BASE_URL
const store = configureStore({
    reducer:{
        user :  userReducer,
        vouchers : voucherReducer,
        merchants : merchantsReducer,
        categories : categoriesReducer,
    }
})


export default store